.projects__container{
    
    background: linear-gradient(to top right , rgb(53, 51, 51) , rgb(44, 44, 44)) !important;
   
}
.projects__wrapper{
    display: flex;
}


.proj__contain{
  
  
    // background: linear-gradient(to  top right , #58b792,#036640) !important ;
 border-radius: 10px;
display: flex;
padding: 2rem;
flex-wrap: wrap;
justify-content: center;
align-items: center;
gap: 1.5rem;

@media screen and (max-width: 800px) {
    flex-direction: column;
    padding: 0.5rem;
    
      }
}
.project__contain{
    width: 46%;
    padding: 1rem;
    .card{
        background-color: transparent;
        cursor: pointer;
    }
  
   
    img{
        height: 300px;
        border: 1px solid black;
        border-radius: 5px;
       
    }
    img:hover{
        opacity: 0.5;
    }
    
    @media screen and (max-width: 800px) {
        width: 90%;
        img{
            height: 300px;
        }
          }
          @media screen and (max-width: 500px) {
            width: 100%;
            img{
                height: 250px
            }
            
              }
 
}

.icon__project{
    color: #0275d8 ;
}