
.contact__form__container{

display: flex;
flex-direction: column;

padding: 1rem 2rem;
@media only screen and (max-width: 800px) {
  width: 80%;
 
    }  
    @media only screen and (max-width: 400px) {
      width: 90%;
     
        } 

.contact__form{
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: 0 auto;
    padding-top: 1rem;
    gap: 1rem;

    
    @media only screen and (max-width: 800px) {
      width: 100%;
     
        }  
}
form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 4px solid rgba(0, 206, 158, 1);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 4px solid rgba(0, 206, 158, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(24, 11, 2);
      color: white;
      border: none;
      width: 40%;
      margin: 0 auto;
    }
  }


  #contact>h3{

    margin: 0 auto;
   width: fit-content;
    text-align: center;
    padding: 1rem 0rem;
    border-bottom: 4px solid white;
    // z-index: 10;
   
}


.contact__title{
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
 
  gap: 1rem;
  // clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
  background-color: #6969694e;
  // z-index: 1;
  padding: 1em;
  border-radius: 10px;

align-self: flex-start;

  span{
    height: 60px;
    font-size: 2rem;
   padding-bottom: 1rem;
 
  }
  h3{
 
    // height: 50px;
    // line-height: 4rem;
    padding-bottom: 1rem;
  }
}
}