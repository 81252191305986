#competences{
    background: linear-gradient(to  top right , #58b792,#036640) !important ;
    margin: 0;
    padding: 0;
}

.fa-code{
    margin-right: 1em;
    
}
.container-fluid{
    padding-bottom: 50px;
}
.comp-contain{
    border-radius: 10px;
    background: linear-gradient(to top right , rgb(53, 51, 51) , rgb(44, 44, 44)) !important;
    box-shadow: 0 0 20px black;


    

}

.doughnut{
    display: flex;

}

#contact{
    background: linear-gradient(to  top right , #58b792,#036640) !important ;
display: flex;
flex-direction: column;
justify-content: center;
  align-items:center;
}