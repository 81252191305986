.banner-image1{
    background-image: url('../../assets/background.webp');  

    margin: 0 auto;
    background-size: cover;
    background-blend-mode: multiply;
    background-color: rgba(0,0,0,0.9); 
    z-index: -2;
    margin-top: 75px !important;   
   
}

.hero{
    font-family: 'Lobster Two', cursive;
}
.ouadie{
    background:linear-gradient(to right bottom ,#1da0f2bd , #6cc3fa  );
    padding: 0 0.2em ;
    display: inline-block;
    transform: skewX(-10deg) ;
    border: 0px solid beige;
}

.hexagone{
    position: relative;
    width: 350px;
    height:350px;
   
   
    @media screen and (max-width: 800px) {
      
            position: relative;
            width: 250px;
            height:250px;
            
        
    }
 
   
}


// .shape{
//     position: absolute;
//     top:0;
//     left:0;
//     width: 100%;
//     height: 100%;
//     // clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
//     border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
 
//     background-color: #000;
//     box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.9);
    
   
// }
.shape > img{
   border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
//    height: 350px;
   @media only screen and (max-width: 800px) {
               
    // width: 50%;
    // height: 50%;
}
}
.cv{
    font-family: 'Poppins', sans-serif;
    font-weight: bold !important;
}

@keyframes moveUpDown {
    0%, 100% {
        top: 4%;
    }
    50% {
        top: 8%; 
    }
}
.mail{
    width: 70px;
    height: 70px;
    border-radius: 50px;
    background-color: #d44638;
    position: absolute;
    top:4%;
    left:4%;
    animation: moveUpDown 2s infinite ease-in-out;
    color: transparent;
}
.mail:hover{
    color: transparent;
}

.fa-envelope{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    color: white;
    
}


.title-container{
  display: flex;
  gap: 1rem;
}
.github__container{
    text-decoration: none;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: baseline;
    margin: 0;
    padding: 0;
    border: 1px solid white;
    width: 18rem;
    height: 5em;
    color: white;
    cursor: pointer;
    .github__icon{
        font-size: 2rem;
        // border: 1px solid blue;
    }
    h2{
        // border: 1px solid blue;
        line-height: 4.5rem;
        
       
    }
    @media only screen and (max-width: 800px) {
               
        width: 10rem;
        height: 3.5rem;
        // align-items: center;
        h2{
            // border: 1px solid blue;
            line-height: 0rem;
          

        }
     
    }
}.github__container:hover{
    color: #7ec0ea;
  
} 


