.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .modal__content {
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    color: black;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    width: 70%;
    border: 2px solid black;
  max-height: 800px;
  overflow: scroll;
 border-radius: 5px;
  margin-top:5rem ;
    @media screen and (max-width: 800px) {
      width: 90%;
    }

    .site__container{
      padding-top: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
    
    .github__logo{
     
      padding: 0.5rem 1.7rem;
      text-decoration: none;

      border-radius: 10px;
      color: black;
      font-size: 1.5rem;
      background-color: rgba(255, 255, 255, 0.785);
      border: 1px solid black;
      align-self: flex-end;
      @media screen and (max-width: 800px) {
        padding: 0.4rem 1.2rem;
        font-size: 1rem;
    }
    }
    .site__logo{
     
      padding: 0.5rem 0.8rem;
      text-decoration: none;
      border-radius: 10px;
      color: black;
      font-size: 1.5rem;
      background-color: rgba(255, 255, 255, 0.785);
      border: 1px solid black;
      align-self: flex-end;
      @media screen and (max-width: 800px) {
        padding: 0.4rem 0.6rem;
        font-size: 1rem;
    }
    }
  }
    // .image-gallery {
    //   overflow: auto; 
    //   height:600px; 
    //   margin-top: 20px; 
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: space-around;
    //   gap: 20px;
    //   width: 100%;
      
   
    // }
    
    // .image-gallery img {
    //   display: block;
    //   width: 42%;
    //   min-height: 30%;
    //   margin-bottom: 10px;
    //   // border: 2px solid rgb(151, 151, 151);
    //   border-radius: 5px;
    //   @media screen and (max-width: 800px) {
    //     width: 95%;
    //   }
    // }
  }
  
  .modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
  }

  .tech-buttons{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
     justify-content: center;
     gap: 1.5rem;
     padding: 0rem 0.2rem;
  }
  .tech-button{
background-color: black;
color: white;
padding: 0.5rem 1.2rem;
border: 0px solid blue;
border-radius: 25px;
  }
