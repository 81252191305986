.propos__container{
    background-color: rgb(3, 2, 2);
    background-origin: border-box;
    background-clip: content-box, border-box;
    position: relative;
    min-height: 20rem;

    .propos__desktop, .propos__mobile{
        max-width: 60%;
        padding: 2.5rem 0rem;
        font-size: 1.2rem;
    
    @media screen and (max-width: 900px) {
        max-width: 80%;
        font-size: 1rem;
    }
    @media screen and (max-width: 600px) {
        max-width: 75%;
       
    }
   }
 
}

.propos__desktop{
    @media screen and (max-width: 900px) {
      display: none;
    }
}

.propos__mobile{
    @media screen and (min-width: 900px) {
      display: none;
    }
}


  