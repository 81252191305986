body{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color:white !important;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  
}

p{
  line-height: 2em !important;
  letter-spacing: 0.6px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
