.progress__container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 13px 0;
    gap: 10px;
    @media screen and (max-width: 765px) {
       flex-direction: column;
       align-items: start;
        width: 90%;
        margin: 0 auto;
       
         }

}
